import { initClient } from '@ts-rest/core'
import { contract } from '@forgd/contract'
import {accessTokenCookie, COOKIE_FORGD_ACCESS_TOKEN} from "#auth/composables/useAuth";

export function useClient() {
  const runtimeConfig = useRuntimeConfig()
  const baseUrl = import.meta.server ? runtimeConfig.public.apiUrl : '/proxy/'

  // TODO we shouldn't need to support server at some point
  const headers: Record<string, string> = import.meta.server
    ? {
        cookie: `${COOKIE_FORGD_ACCESS_TOKEN}=${accessTokenCookie.value}`,
      }
    :
    // cookie header is automatically set by the browser
    {}

  return initClient(contract, {
    baseUrl,
    baseHeaders: headers,
    credentials: 'include',
  })
}

import type { ClientInferResponseBody } from '@ts-rest/core'
import type { contract } from '@forgd/contract'
import { useStorage } from '@vueuse/core'
import type { NavigateToOptions } from '#app/composables/router'

type Projects = ClientInferResponseBody<
  typeof contract.projects.getProjects,
  200
>
export type User = ClientInferResponseBody<typeof contract.users.me, 200>
export type Organization = Omit<User['organizations'][number], 'projects'>
export type Project = User['organizations'][number]['projects'][number]

type OrganizationWithProjects = Organization & { projects: Project[] }

// these cookies are needed outside of the auth store
export const COOKIE_FORGD_ACCESS_TOKEN = 'forgd-access-token'
export const accessTokenCookie = useCookie(COOKIE_FORGD_ACCESS_TOKEN)
export const refreshTokenCookie = useCookie('forgd-refresh-token')

export const useAuth = defineStore('auth', () => {
  const client = useClient()
  const route = useRoute()

  const loggedIn = ref<boolean | null>(null)
  const isLoggingIn = ref<boolean>(false)
  const isLoggingOut = ref<boolean>(false)
  const me = ref<User | null>(null)
  const organizations = ref<OrganizationWithProjects[] | null>(null)
  const organization = ref<OrganizationWithProjects | null>(null)
  const project = ref<Project | null>(null)
  const pending = ref(false)
  const ticker = computed(() => project.value?.ticker?.toUpperCase())
  const authSwrCache = useStorage('forgd:auth:cache', null, import.meta.client ? window.localStorage : undefined, {
    listenToStorageChanges: true,
    serializer: {
      read: (v: any) => v === null ? null : JSON.parse(v),
      write: (v: any) => JSON.stringify(v),
    },
  })
  const onboardingPath = useRuntimeConfig().public.featureFlags.onboarding.path

  const supabase = useSupabaseClient()

  // watch for auth state changes to sync tabs
  watch(authSwrCache, (val) => {
    // if the auth cache has been reset, we should see if we need to logout
    if (!val) {
      // scenario is that another tab has logged out when this code runs
      if (loggedIn.value) {
        logout()
      }
    }
    //
    else if (!loggedIn.value) {
      redirectAuthenticatedHome({
        external: true,
      })
    }
  })
  const isOrganizationOwner = computed(() => organization.value?.ownerUserId === me.value?.id)

  // if this exists then we're authenticating
  const authCheckPromise: Ref<Promise<boolean> | null> = ref(null)

  async function doAuthFetch() {
    pending.value = true
    const mePayload = await client.users.me().catch((err) => {
      console.error('Auth failed', err)
      return null
    }).finally(() => {
      pending.value = false
    })
    if (mePayload?.status !== 200) {
      if (route.path !== '/login') {
        window.location.href = '/login'
      }
      return false
    }

    loggedIn.value = true
    me.value = mePayload.body
    organizations.value = (me.value.organizations || []) as any as OrganizationWithProjects[]

    let _project = project.value
    let _organization = organization.value
    // we have hydrated project and organization from the cache HOWEVER they may not match the new payload
    // so we select the org and project IF they match, otherwise fallback to the first of each
    if (_project || _organization) {
      let matched = false
      for (const org of mePayload.body.organizations || []) {
        for (const p of org.projects || []) {
          if (p.id === _project?.id && p.id === _organization?.id) {
            _project = p
            _organization = org
            matched = true
          }
        }
      }
      if (!matched) {
        _project = null
        _organization = null
      }
    }

    // avoid using any stale data for hydration
    organization.value = _organization || organizations.value?.[0]
    project.value = _project || organization.value.projects?.[0]
    authSwrCache.value = {
      me: me.value,
      organization: organization.value,
      project: project.value,
    }
    return true
  }

  // opt-in swr hydration of auth payload
  async function check(options?: { swr?: boolean, onFailure?: () => Promise<void> | void }) {
    // avoid multiple auth checks running at once
    if (authCheckPromise.value) {
      return authCheckPromise.value
    }
    return authCheckPromise.value = new Promise<boolean>((resolve) => {
      // we apply SWR logic to authentication
      if (options?.swr && authSwrCache.value) {
        // hydrate from payload
        const { me: _me, organization: _organization, project: _project } = authSwrCache.value
        me.value = _me
        organization.value = _organization
        project.value = _project
        loggedIn.value = true
        // do the auth check async, don't block the user
        doAuthFetch().then((res) => {
          !res && options?.onFailure?.()
        })
        return resolve(true)
      }
      doAuthFetch().then(async (res) => {
        !res && await options?.onFailure?.()
        resolve(res)
      })
    }).finally(() => {
      authCheckPromise.value = null
    })
  }

  /**
   * Redirect the user if they're authenticated to the default starting page.
   */
  function redirectAuthenticatedHome(options: NavigateToOptions = {}) {
    // overriding
    if (redirectTo.value) {
      navigateTo(redirectTo.value, options)
      redirectTo.value = null
      return
    }
    if (me.value?.isOnboarded) {
      return navigateTo('/dashboard/', options)
    }
    else {
      return navigateTo(onboardingPath, options)
    }
  }

  async function login(options: { email: Ref<string>, password: Ref<string> }) {
    isLoggingIn.value = true

    const { data, error } = await supabase.auth.signInWithPassword({
      email: options.email.value,
      password: options.password.value,
    })

    if (error) {
      isLoggingIn.value = false
      return [data, error]
    }

    if (data.session) {
      if (data.session.access_token) {
        accessTokenCookie.value = data.session.access_token
      }

      if (data.session.refresh_token) {
        refreshTokenCookie.value = data.session.refresh_token
      }
    }

    await auth.check()

    auth.redirectAuthenticatedHome()
    loading.value = false
    return [data, error]
  }

  async function logout() {
    // Logout is a multi-step process:
    // 1. sign out via supabase, this can take a second so we keep the loading state
    // 2. navigate to the login page with a special query param to indicate logout
    // 3. on the login page we check for this param and clear the persisted state

    // We need to do this to avoid any reactive updates for the page the user is on,
    // this means we should be able to guarantee the user exists in an authenticated page
    isLoggingOut.value = true
    await supabase.auth.signOut()
      .catch(() => {
        isLoggingOut.value = false
      })
      .then(() => {
        // an external navigation will clear some state but not the data in localStorage
        // so we need to give the login page a hint to clear the localStorage data
        navigateTo('/login?action=logout', { external: true })
      })
  }
  /**
   * Note: this needs to be called in addition to the Supabase signOut function
   */
  function clear() {
    loggedIn.value = false
    // full clean up of args to avoid stale data when switching accounts
    me.value = null
    project.value = null
    organization.value = null
    organizations.value = null
    authSwrCache.value = null
    accessTokenCookie.value = null
    refreshTokenCookie.value = null
    refreshCookie('forgd-access-token')
    refreshCookie('forgd-refresh-token')
  }

  function switchProject(newProject: Projects) {
    project.value = newProject
    authSwrCache.value = {
      me: me.value,
      organizations: organizations.value,
      project: project.value,
    }
  }

  const redirectTo = ref<string | null>(null)

  return {
    organization,
    organizations,
    redirectTo,
    loggedIn,
    me,
    isOrganizationOwner,
    project,
    ticker,
    isLoggingOut,
    login,
    logout,
    pending,
    redirectAuthenticatedHome,
    clear,
    check,
    switchProject,
  }
})

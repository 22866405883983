import { z } from 'zod'
import { TProject } from '../projects'
import {
  BaseLayerTypeArray,
  MemberStatusEnum,
  SectorTypeArray,
} from '@forgd/supabase'

export const UserResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      ownerUserId: z.string(),
      createdAt: z.date(),
      updatedAt: z.date(),
      deletedAt: z.date(),
    }),
  ),
})

const dateTimeLocal = z.string().datetime({ local: true })

const GetMeProject = TProject.omit({
  areasOfInterest: true,
}).extend({
  organizationId: z.string().uuid().nullable(),
  createdAt: dateTimeLocal,
  updatedAt: dateTimeLocal,
  deletedAt: dateTimeLocal.nullable(),
  tgeDate: z.string().datetime({ offset: true }).nullable(),
  baseLayer: z.array(z.enum(BaseLayerTypeArray)).nullable(),
  sector: z.array(z.enum(SectorTypeArray)).nullable(),
})

const GetMeOrganization = z.object({
  id: z.string(),
  name: z.string(),
  ownerUserId: z.string(),
  createdAt: dateTimeLocal,
  updatedAt: dateTimeLocal,
  deletedAt: dateTimeLocal.nullable(),
  projects: z.array(GetMeProject),
  membersCount: z.number(),
  memberStatus: z.nativeEnum(MemberStatusEnum),
})

export const GetMeResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: dateTimeLocal,
  updatedAt: dateTimeLocal,
  deletedAt: dateTimeLocal.nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(GetMeOrganization),
})

export type UserMeResponse = z.infer<typeof GetMeResponse>
export type OrganizationMeResponse = z.infer<typeof GetMeOrganization>
export type ProjectMeResponse = z.infer<typeof GetMeProject>
